<template>
    <div>
        <!-- 面包屑导航区域 这个组件的样式其他组件也用的到 所以写到公共样式里 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item> <!-- 点击回到welcome 因为重定向 -->
            <el-breadcrumb-item>数据采集</el-breadcrumb-item>
            <el-breadcrumb-item>筛查列表</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 卡片视图区域 -->
        <el-card>
            <!-- layout栅格 设置多少行多少列 span是宽度 gutter是间隙 -->
            <el-row :gutter="5">
                <el-col :span="7">
                    <div class="block">
                        <span class="demonstration">检查时间：</span>
                        <el-date-picker v-model="timeObj" type="daterange" value-format="yyyy-MM-dd" range-separator="至"
                            start-placeholder="开始日期" end-placeholder="结束日期" @change="changeDate">
                        </el-date-picker>
                    </div>
                </el-col>
                <el-col :span="6">
                    <el-input v-model="queryInfo.name" placeholder="请输入内容" clearable @clear="getUserList()"></el-input>
                </el-col>
                <el-col :span="2"><el-button type="primary" icon="el-icon-search"
                        @click="getUserList()">搜索</el-button></el-col>
            </el-row>

            <!-- 用户列表区域 -->
            <el-table :data="list" border stripe>
                <!-- 跟menu一样 把要展示的数据存储到table自带的属性data里面 下面再用prop取对应的数据 和v-model双向绑定 -->

                <el-table-column type="index"></el-table-column> <!-- column索引列 只要加上type="index" -->
                <el-table-column label="用户名" prop="userName"></el-table-column>
                <el-table-column label="年级" prop="gradeName"></el-table-column>
                <el-table-column label="班级" prop="className"></el-table-column>
                <el-table-column label="左足弓类型" prop="archTypeL">
                </el-table-column>
           
                <el-table-column label="左脚图片" prop="imgL">
                    <template slot-scope="scope">
                        <div class="foot_box">
                        <img style="height: 50px;transform: scaleX(-1);" :src="scope.row.imgL" />
                        <img @click="analyzeOneFoot(scope.row.id,scope.row.imgL,'l')" src="@/assets/cxjc.png" alt="">
                    </div>
                    </template>
                </el-table-column>
                <el-table-column label="右脚图片" prop="imgR">
                    <template slot-scope="scope">
                        <div class="foot_box">
                            <img  :src="scope.row.imgR" /> 
                             <img @click="analyzeOneFoot(scope.row.id,scope.row.imgR,'r')" src="@/assets/cxjc.png" alt="">
                        </div>
              
                    </template>
                </el-table-column>
                <el-table-column label="左足弓类型" prop="archTypeR">
                </el-table-column>
                <el-table-column label="创建时间" prop="createTime"></el-table-column>
                
                <!-- <el-table-column label="操作" width="180px">
                    <template slot-scope="scope">
                        <el-button type="primary" icon="el-icon-edit" size="mini" @click="amend(scope.row)"></el-button>
                        <el-button type="danger" icon="el-icon-delete" size="mini"
                            @click="removeUserById(scope.row.id)"></el-button>
                    </template>
                </el-table-column> -->

            </el-table>
            <!-- 分页区域 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="queryInfo.page" :page-sizes="[1, 2, 5, 10]" :page-size="queryInfo.pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="total"> <!-- layout表示显示哪些东西 -->
            </el-pagination>

        </el-card>


        <!-- 结果重新分析修改 -->
        <el-dialog
  title="检测结果"
  :visible.sync="amendDialogVisible"
  width="30%"
>

<div>
    <img :src="imageUrl" :style="amendType=='l'?'transform: scaleX(-1);':''" alt="">
    <div class="result_span">当前检测结果：{{ amendResult }}</div>

</div>
<div style="margin-top: 30px;">
    其它结果：
    <el-select v-model="selectedOption" :placeholder="selectedOption" style="margin-right: 10px;"   @change="handleChange">
        <el-option label="重度高弓" value="重度高弓"></el-option>
        <el-option label="中度高弓" value="中度高弓"></el-option>
        <el-option label="轻度高弓" value="轻度高弓"></el-option>
        <el-option label="正常" value="正常"></el-option>
        <el-option label="轻度扁平" value="轻度扁平"></el-option>
        <el-option label="中度扁平" value="中度扁平"></el-option>
        <el-option label="重度扁平" value="重度扁平"></el-option>
        <el-option label="未站好" value="未站好"></el-option>
      </el-select>
    </div>
  <span slot="footer" class="dialog-footer">




    <el-button @click="amendDialogVisible = false">取 消</el-button>





    <el-button type="primary" @click="clickMendResult">修改检测结果</el-button>
  </span>
</el-dialog>

    </div>
</template>

<script>
import axios from "axios";
export default {
    data() {
        return {
            queryInfo: {
                name: '',
                page: 1,
                pageSize: 10,
                type: 1,
                startTime:'',
                endTime:''
            },
            imageUrl:"",
            timeObj: "",
            list: [], /* 返回的数据存储到这里 */
            total: 0,
            addDialogVisible: false,
            amendId:0,
            amendDialogVisible: false,
            amendResult:"",
            amendType :"l",   //修改类型 l=左脚
            selectedOption: '',
            addForm: {
                name: '',
                mobile: '',
                updateTime: ''
            },
        }
    },
    created() {
        this.getUserList()
    },
    methods: {
        handleChange(value){
         console.log(value);
         this.amendResult = value;
         this.clickMendResult();
        },
        clickMendResult(){
            this.$http.get('AgileInstrument/amendResult', {
                params: {
                    id:this.amendId,
                    amendType:this.amendType,
                    amendResult:this.amendResult,
                }
            }).then((res) => {
                console.log(res);
                let data = res.data;
                if (data.code != 1) {
                        
                return this.$message.success('获取用户列表失败！')
                    return
                }
    
      this.amendDialogVisible = false;
                this.getUserList();

            })
        },


        analyzeOneFoot(id,url,type){
            let that = this;
            that.amendId = id;
that.amendType = type;



//             let  result = "未站好 (Confidence: 1.00)"
//             const regex = /^(.*?) \(Confidence:\s*([\d.]+)\)$/;
//             const arr = result.match(regex);
//             that.imageUrl = url;
//             that.amendDialogVisible =true;
// console.log(arr);

//             that.amendResult = arr[1]
            // --------------

let obj = {
    image_url :     url,
    amendType:type
            }



   // 这个是单独的服务

   axios.post('https://eyetest.cc/aiAnalysis/analyze_one_foot', obj)
        .then(res => {
         
            console.log(res);
            if(res.data&&res.data.result){
     
                that.imageUrl = url;
            that.amendDialogVisible =true;
          // 请求成功，处理响应数据
            const regex = /^(.*?) \(Confidence:\s*([\d.]+)\)$/;
            const arr = res.data.result.match(regex);
    

            that.amendResult = arr[1];
        that.selectedOption = arr[1];
            }
       
          
          
        })
        .catch(error => {
          // 请求失败，处理错误
          this.error = error.response ? error.response.data.error : error.message;
        });
        },
        changeDate(e){
            this.queryInfo.startTime = e[0];
            this.queryInfo.endTime = e[1];
            this.getUserList();
        },
       

        amend(data) {
            this.addForm = JSON.parse(JSON.stringify(data))
            this.addDialogVisible = true;
        },
        // --------请求方法-----
        async getUserList() {
            const { data: res } = await this.$http.get('/AgileInstrument/getList', {
                params: this.queryInfo /* 将参数放到data里面 因为下面还要根据选择的条数等重新请求 参数会变 */
            })
            if (res.code != 1) {
                return this.$message.error('获取用户列表失败！')
            }
            this.list = res.data.records
            
            this.total = res.data.total
        },
        handleSizeChange(newSize) {
            // console.log(newSize)
            this.queryInfo.pageSize = newSize
            this.getUserList()
        },
        handleCurrentChange(newPage) {
            console.log(newPage)
            this.queryInfo.page = newPage
            this.getUserList()
        },
    }
}
</script>

<style lang="less">
.foot_box{

    display: flex;
>img:nth-child(1){
    height: 50px
}
>img:nth-child(2){
    height: 20px;
    margin-top: 10px;
    margin-left: 10px;
}
}
.result_span{
    font-size: 20px;font-weight: 700;text-align: center;
}
.el-dropdown {
    vertical-align: top;
  }
  .el-dropdown + .el-dropdown {
    margin-left: 15px;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
</style>